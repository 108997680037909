import FormControl from 'components/common/form-control/FormControl';
import Switch from 'components/common/switch/Switch';
import Title, { TextType } from 'components/common/title/Title';
import { ClosedDayType, Point } from 'types/feature/workspace/workspace';
import HolidayForm from './HolidayForm';
import Button from 'components/common/button/Button';
import { ButtonColor } from 'components/common/button/Button.type';
import ConfirmPopup from 'components/feature/workspace/popup/ConfirmPopup';
import usePoint from 'hooks/feature/point/usePoint';
import { useState } from 'react';

type Props = {
  point: Point;
};

const Holiday = ({ point }: Props) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const { patchOperationUsed, patchClosedDayType } = usePoint();
  const [selectedType, setSelectedType] = useState<ClosedDayType>('SOON');
  const [isUsed, setUsed] = useState<boolean>(point?.operation?.closedDaysUsed || false);
  const handleChangeOption = async (closedDaysUsed: boolean) => {
    setUsed(closedDaysUsed);
  };

  const handleUpdate = async () => {
    try {
      await patchOperationUsed(point.id, isUsed);
      await patchClosedDayType(point.id, { closedDayType: selectedType });
      setOpenConfirm(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (!point) {
    return <></>;
  }

  return (
    <>
      <Title text='휴무일 설정' textType={TextType.h3_bold} />
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-2.5'>
          <FormControl
            name='휴무일 사용여부'
            required
            control={<Switch type='USE' color='BLUE' value={isUsed} onChangeOption={handleChangeOption} />}
          />
        </div>
        {isUsed && (
          <FormControl
            name='날짜 선택'
            required
            control={<HolidayForm pointId={point.id} selectedType={selectedType} setSelectedType={setSelectedType} />}
          />
        )}
      </div>
      <div className='flex justify-end'>
        <Button text='저장' color={ButtonColor.point} size={80} onClick={() => setOpenConfirm(true)} />
      </div>
      {openConfirm && (
        <ConfirmPopup
          message='저장 하시겠습니까?'
          onClickCancel={() => setOpenConfirm(false)}
          onClickOk={handleUpdate}
        />
      )}
    </>
  );
};

export default Holiday;
